import React from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Title3,
  Large,
  colors,
} from 'js-components'
import { navigate } from 'gatsby';
import { Section, WrapperBackground } from '../../components/Wrapper'

import WorkableAndTruly from './customerLogos/workableAndTruly.svg'

const AboutPage = () => (
  <div>
    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene1'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 2/3]}>
          <HeroLogo src={WorkableAndTruly} />
          <Large as="p" color={colors.white} my="1.5rem" center>
          Learn How Workable Up Leveled Their Voice Solution with Truly's Success Practice
          </Large>
          <Button size="extraLarge" color={colors.accentFog} round value="Download the Case Study (PDF)" type="outline" onClick={() => navigate('/customers/q')}></Button>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene1'
      gradientType='light'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 1/2]} pl={['0pt', '32pt']}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
          THE PREMIER INTERNATIONAL SOLUTION
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem">
            Workable's sales team makes phone calls in over 68 countries world wide. Truly was the first phone system to provide reliable international calling and a support team that is available 24/7.
          </Large>
        </Box>
        <Box width={[0, 1/2]}>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene1'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[0, 1/2]}>
        </Box>
        <Box width={[1, 1/2]} pl={['0pt', '32pt']}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
          TAKING CALL DATA TO THE NEXT LEVEL
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
          The Sales Ops team at Workable needed a solution that would provide great data but also wanted advice on how to best leverage it. Truly's success practice worked with their team to develop business critical reporting that drives their global strategy.
          </Large>
        </Box>
      </Section>
    </WrapperBackground>

  </div>
)

export default AboutPage

const HeroLogo = styled.img`
  width:50%;
  margin-bottom: 0 auto 32pt auto;
`
